



























import { Vue, Component, VModel, Prop, Emit } from 'vue-property-decorator';

@Component({})
export default class ContentDialog extends Vue {
  @VModel()
  dialog!: boolean;

  @Prop()
  readonly title!: string;

  @Prop({ default: '100%' })
  readonly width!: string;

  @Prop({ default: '100%' })
  readonly maxWidth!: string;

  @Prop()
  readonly dense!: boolean;

  @Emit()
  afterClose(): boolean {
    return this.dialog;
  }

  close(): void {
    this.dialog = false;
  }

  get cardClass(): string {
    return this.dense ? 'pa-1' : 'pa-4';
  }
}
