








































































































































































































































































































































import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import { LoaderComponent } from 'vue-loading-overlay';
import { padStart, pick, random, sortBy } from 'lodash';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import { ClientStatusEnum } from '@/enums/crm/client-status.enum';
import { FormHelper } from '@/utils/helpers/form-helper';
import { DefaultExtensions } from '@/utils/tiptap-extensions/default-extensions';
import { ValidationRules } from '@/utils/helpers/validation-rules-helper';
import Tooltip from '@/components/tooltip.vue';
import Field from '@/components/field.vue';
import DatePickerField from '@/components/date-picker-field.vue';
import ActivityService from '@/services/crm/activity.service';
import TimePickerField from '@/components/time-picker-field.vue';
import { IDialogConfig } from '@/interfaces/dialog-config.interface';
import { ICalendarEvent } from '@/interfaces/crm/calendar-event.interface';
import ActivityEventModel from '@/models/crm/activity-event.model';
import { DateHelper } from '@/utils/helpers/date-helper';
import RepresentativeModel from '@/models/crm/representative.model';
import RepresentativeService from '@/services/crm/representative.service';
import ClientModel from '@/models/crm/client.model';
import dayjs from '@/plugins/dayjs';
import OriginModel from '@/models/crm/origin.model';
import HistoryTypeModel from '@/models/crm/history-type.model';
import GroupModel from '@/models/crm/group.model';
import WysiwygEditor from '@/components/wysiwyg-editor.vue';
import UserModel from '@/models/user.model';
import UserContactInfo from '@/models/crm/user-contact-info.model';
import { ObjectHelper } from '@/utils/helpers/object-helper';
// import { toObjectId } from '@/utils/formatters/to-object-id';
import { toIsoDateString } from '@/utils/formatters/to-iso-date-string';
import UploadService from '@/services/crm/upload.service';
import { IUploadedFile } from '@/interfaces/uploaded-file.interface';
import DragDropUpload from '@/components/drag-drop-upload.vue';
import CrmClientSearchField from '@/components/crm/client-search-field.vue';
import ActivityEventAttachmentModel from '@/models/crm/activity-event-attachment.model';
import { ActivityEventStatusEnum } from '@/enums/crm/activity-event-status.enum';
import { IKeyValue } from '@/interfaces/key-value.interface';
import ClonedUploadModel from '@/models/crm/cloned-upload.model';
import ProcessModel from '@/models/crm/process.model';
import { ClientTypeEnum } from '@/enums/client-type.enum';
import ProspectModel from '@/models/crm/prospect.model';
import ClientService from '@/services/crm/client.service';
import ProspectService from '@/services/crm/prospect.service';

@Component({
  components: {
    Tooltip,
    Field,
    DatePickerField,
    TimePickerField,
    WysiwygEditor,
    DragDropUpload,
    CrmClientSearchField,
  },
})
export default class CrmActivityCalendarEventForm extends Vue {
  @inject(InjectionIdEnum.CrmActivityService)
  private activityService!: ActivityService;

  @inject(InjectionIdEnum.CrmRepresentativeService)
  private representativeService!: RepresentativeService;

  @inject(InjectionIdEnum.CrmUploadService)
  protected uploadService!: UploadService;

  @inject(InjectionIdEnum.CrmClientService)
  private clientService!: ClientService;

  @inject(InjectionIdEnum.CrmProspectService)
  private prospectService!: ProspectService;

  @Prop()
  id!: number;

  @Prop()
  cloneId!: number;

  @Prop()
  defaultClient!: ClientModel;

  @Prop()
  defaultClientType!: ClientTypeEnum;

  @Prop({ required: true })
  userContactInfo!: UserContactInfo;

  @Prop()
  startDate!: Date;

  @Prop()
  endDate!: Date;

  @Prop()
  timed!: boolean;

  @Prop()
  process!: ProcessModel;

  @Prop({ default: false })
  isRescheduling!: boolean;

  @Prop({ default: false })
  blockSaving!: boolean;

  @Prop()
  disableAutoComplete!: boolean;

  formIsValid = true;

  model: ActivityEventModel = plainToClass(ActivityEventModel, {
    id: null,
    cnpj: null,
    prospect: null,
    nome: null,
    representante: null,
    titulo: null,
    descricao: null,
    dataHoraInicio: null,
    dataHoraFim: null,
    atendente: null,
    tipoHistorico: null,
    origem: null,
    anexos: [],
    processo: this.process,
    idRetorno: null,
  });

  rules = {
    titulo: [ValidationRules.required],
    dataHoraInicio: [ValidationRules.required],
    dataHoraFim: [ValidationRules.required],
    representante: [ValidationRules.required],
    atendente: [ValidationRules.required],
    tipoHistorico: [ValidationRules.required],
    grupo: [ValidationRules.required],
    origem: [ValidationRules.required],
  };

  dialogConfig: IKeyValue<IDialogConfig> = {
    sendEventEmail: {
      show: false,
      event: null,
      client: null,
    },
  };

  startAtTime: string | null = null;

  endAtTime: string | null = null;

  clientItems: ClientModel[] = [];

  representativeOptions: RepresentativeModel[] = [];

  originOptions: OriginModel[] = [];

  historyTypeOptions: HistoryTypeModel[] = [];

  groupOptions: GroupModel[] = [];

  attendantOptions: UserModel[] = [];

  group: GroupModel | null = null;

  loadingHistoryTypeOptions = false;

  showAdditionalFields = false;

  showInternalObservation = false;

  eventClosed = false;

  showUploader = false;

  uploadEndpoint = '';

  uploadHash = CrmActivityCalendarEventForm.getHash();

  uploadPlace = 'events';

  searchingClient = false;

  searchClient = '';

  clientSearchKey = 0;

  private enabledSearchingClient = false;

  private client: ClientModel | null = null;

  private clientType: ClientTypeEnum = ClientTypeEnum.Client;

  private readonly debounce = 450;

  private debounceId!: number;

  private extensions = DefaultExtensions.getExtensionsNoTable();

  @Emit('cancel')
  onCancel(): void {
    // Before go away, delete non uploaded files
    this.model.anexos.filter((file) => !file.id).forEach((file) => this.uploadService.delete(file.path));

    FormHelper.resetValidation(this.$refs.form as Vue);
  }

  @Watch('model.tipoHistorico')
  watchHistoryType(value: HistoryTypeModel): void {
    if (this.id === null && value.textoPadraoEvento) {
      this.showAdditionalFields = true;
      this.model.descricao = value.textoPadraoEvento;
    }
  }

  @Watch('searchClient')
  watchSearchClient(value: string): void {
    clearTimeout(this.debounceId);

    if (!this.enabledSearchingClient || !value || !value.length) {
      return;
    }

    if (value.length < 2) {
      this.clientItems = [];
      this.searchingClient = false;
      return;
    }

    this.searchingClient = true;
    this.debounceId = setTimeout(async () => {
      try {
        if (this.clientType === ClientTypeEnum.Client) {
          const result = await this.clientService.quickSearch(value, ClientTypeEnum.Client);
          this.clientItems = result.map((item) => {
            const newItem = item;
            newItem.nomeFantasia = newItem.nomeFantasia || newItem.nome;
            return newItem;
          });
        } else {
          const result = await this.prospectService.quickSearch(value);
          this.clientItems = result
            .filter((item) => !item.flagExcluido)
            .map((item) => {
              const newItem = item;
              newItem.fantasia = newItem.fantasia || newItem.razaoSocial || newItem.contato;
              return newItem.clientModel;
            });
        }
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        this.searchingClient = false;
      }
    }, this.debounce);
  }

  async mounted(): Promise<void> {
    this.uploadEndpoint = this.uploadService.getUploadUrl();

    const loader = this.setBusyLoader();
    try {
      const getOptionsTask = this.loadOptions();
      const getEventTask = this.loadModel();
      await Promise.all([getOptionsTask, getEventTask]);

      // If a default client type is provided
      if (this.defaultClientType) {
        this.clientType = this.defaultClientType;

        // Otherwise get client type from loaded event
      } else if (this.model?.tipo) {
        this.clientType = this.model.tipo;
      }

      // if default client is provided
      if (this.defaultClient) {
        this.client = this.defaultClient;
        // Otherwise get client from loaded event
      } else if (this.clientType && (this.id || this.cloneId)) {
        if (this.isProspectType) {
          this.client = this.model.prospect?.clientModel as ClientModel;
        } else {
          this.client = await this.clientService.getSummary(this.model?.cnpj as string, this.clientType);
        }
      }

      if (this.client) {
        this.clientItems = [this.client];
        this.model.cnpj = this.client.cnpjCpf;
        this.model.nome = this.client.nome;
        this.clientSearchKey += 1;
      }

      if (this.isProspectType) {
        const prospect = new ProspectModel();
        prospect.codProspect = this.client?.codCliente as string;
        this.model.prospect = prospect;
      }

      // Set default values when creating new
      if (!this.id && !this.cloneId) {
        this.model.dataHoraInicio = this.startDate;
        this.model.dataHoraFim = this.endDate;

        if (this.timed) {
          let startDate = dayjs(this.startDate);
          const endDate = dayjs(this.endDate);

          if (startDate.isSame(endDate)) {
            startDate = startDate.startOf('hour');

            this.startAtTime = startDate.format('HH:mm');
            this.endAtTime = startDate.add(1, 'hour').format('HH:mm');
          } else {
            this.startAtTime = startDate.format('HH:mm');
            this.endAtTime = endDate.format('HH:mm');
          }
        }

        if (this.client?.codRepres) {
          this.model.representante = this.representativeOptions
            .find((x) => (x.chaveIntegracao
              ? x.chaveIntegracao.toUpperCase() === this.client?.codRepres?.toUpperCase()
              : x.codigo.toUpperCase() === this.client?.codRepres?.toUpperCase()));
        }

        this.model.atendente = this.attendantOptions.find((x) => x.id === this.userContactInfo.id);

        // otherwise handle data to edition
      } else {
        const startDate = dayjs(this.model.dataHoraInicio);
        const endDate = dayjs(this.model.dataHoraFim);

        if (this.model.timed) {
          this.startAtTime = startDate.format('HH:mm');
          this.endAtTime = endDate.format('HH:mm');
        }

        const initialGroup = this.model.tipoHistorico.grupoArea?.id;
        this.loadHistoryTypes(initialGroup);
        this.group = this.groupOptions.find((x) => x.id === initialGroup) || null;
      }

      this.eventClosed = this.model.situacao === ActivityEventStatusEnum.Closed;
      this.showAdditionalFields = !!this.model.descricao || !!this.model.anexos?.length;

      setTimeout(() => {
        this.enabledSearchingClient = true;
      }, 500);
    } catch (err) {
      this.$notify.error(err && (err as Error).message);
    } finally {
      loader.hide();
    }
  }

  onSelectClient(client: ClientModel | null): void {
    this.client = client;
    if (this.client) {
      this.clientItems = [this.client];
      this.model.cnpj = this.client.cnpjCpf;
      this.model.nome = this.client.nome;
      this.model.prospect = undefined;

      if (this.isProspectType) {
        const prospect = new ProspectModel();
        prospect.codProspect = this.client.codCliente as string;
        this.model.prospect = prospect;
      }

      this.model.representante = this.representativeOptions.find((x) => x.codigo === this.client?.codRepres);
    } else {
      this.model.cnpj = undefined;
      this.model.nome = '';
      this.model.prospect = undefined;
    }
  }

  onClientTypeChange(): void {
    this.client = null;
    this.clientItems = [];
  }

  async onSave(): Promise<void> {
    if (FormHelper.validate(this.$refs.form as Vue)) {
      const loader = this.setBusyLoader();
      try {
        const model = ObjectHelper.mapObjectValues<ActivityEventModel>(this.model, null, {
          representante: (object) => pick(object, ['codigo', 'nome']),
          origem: (object) => pick(object, ['id', 'descricao']),
          tipoHistorico: (object) => pick(object, ['id', 'nome']),
          atendente: (object) => pick(object, ['id', 'nome']),
        });

        model.timed = !!this.startAtTime || !!this.endAtTime;

        if (this.id) {
          // When editing, before save, remove already attached files from model
          model.anexos = model.anexos.filter((x) => !x.id).map(({ filename, path }) => ({ filename, path }));

          await this.activityService.update(model);
        } else {
          if (model.anexos?.length) {
            const clonedFileTask: Promise<ClonedUploadModel>[] = [];
            const clientId = this.isProspectType ? this.client?.codCliente : this.client?.cnpjCpf;

            // If in "duplicate mode", clone attachments  before save
            model.anexos
              .filter((file) => file.id)
              .forEach((file) => {
                clonedFileTask.push(
                  this.uploadService.clone(
                    file.path,
                    file.filename,
                    clientId as string,
                    this.uploadPlace,
                    this.uploadHash,
                  ),
                );
              });

            const cloneFileResult = await Promise.all(clonedFileTask);
            const clonedFiles = cloneFileResult.map(({ filename, path }) => ({ filename, path }));

            // New files that will be attached in the event
            const newFiles = model.anexos.filter((x) => !x.id).map(({ filename, path }) => ({ filename, path }));

            model.anexos = [...newFiles, ...clonedFiles];
          }

          const result = await this.activityService.create(model);
          model.id = result;

          if (this.isRescheduling) {
            model.idRetorno = result;
            await this.activityService.update(model);
          }
        }

        if (this.eventClosed && this.model.situacao !== ActivityEventStatusEnum.Closed) {
          await this.activityService.close(model.id, !!model.flagEfetuouVenda);
        }

        this.$notify.success(this.$t('crm.activityCalendarEventForm.successfullySave'));

        this.$emit('complete', model);
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        loader.hide();
      }
    }
  }

  onSaveAndEmail(): void {
    this.model.flagEnviarEmail = 1;
    this.onSave();
  }

  onStartAtChange(): void {
    if (this.startAtTime) {
      const time = CrmActivityCalendarEventForm.extractTime(this.startAtTime);
      this.model.dataHoraInicio = DateHelper.setTime(this.model.dataHoraInicio, time[0], time[1]);
    }

    this.validateDate();
  }

  onStartAtTimeChange(): void {
    if (!this.model.dataHoraInicio) {
      return;
    }

    let time = [0, 0];
    if (this.startAtTime) {
      time = CrmActivityCalendarEventForm.extractTime(this.startAtTime);

      if (!this.endAtTime) {
        this.endAtTime = this.startAtTime;
        this.model.dataHoraFim = DateHelper.setTime(this.model.dataHoraFim, time[0], time[1]);
      }
    } else if (this.endAtTime) {
      this.endAtTime = null;
      this.model.dataHoraFim = DateHelper.setTime(this.model.dataHoraFim, 0, 0);
    }

    this.model.dataHoraInicio = DateHelper.setTime(this.model.dataHoraInicio, time[0], time[1]);

    this.validateDate();
  }

  onEndAtChange(): void {
    if (this.endAtTime) {
      const time = CrmActivityCalendarEventForm.extractTime(this.endAtTime);
      this.model.dataHoraFim = DateHelper.setTime(this.model.dataHoraFim, time[0], time[1]);
    }

    this.validateDate();
  }

  onEndAtTimeChange(): void {
    if (!this.model.dataHoraFim) {
      return;
    }

    let time = [0, 0];
    if (this.endAtTime) {
      time = CrmActivityCalendarEventForm.extractTime(this.endAtTime);

      if (!this.startAtTime) {
        this.startAtTime = this.endAtTime;
        this.model.dataHoraInicio = DateHelper.setTime(this.model.dataHoraInicio, time[0], time[1]);
      }
    } else if (this.startAtTime) {
      this.model.dataHoraInicio = DateHelper.setTime(this.model.dataHoraInicio, 0, 0);
    }

    this.model.dataHoraFim = DateHelper.setTime(this.model.dataHoraFim, time[0], time[1]);

    this.validateDate();
  }

  onGroupChange(model: GroupModel): void {
    this.loadHistoryTypes(model.id);
  }

  onAddAttachment(): void {
    this.uploadHash = CrmActivityCalendarEventForm.getHash();
    this.showUploader = true;
  }

  onCloseUploader(files: IUploadedFile[]): void {
    this.showUploader = false;
    this.model.anexos = [...this.model.anexos, ...files];
  }

  async onDeleteAttachment(file: ActivityEventAttachmentModel): Promise<void> {
    this.uploadService.delete(file.path);
    this.model.anexos = this.model.anexos.filter((x) => x.path !== file.path);

    if (file.id && this.id) {
      const loader = this.setBusyLoader();
      try {
        await this.activityService.deleteAttachment(this.id, file.id);
      } catch (err) {
        this.$notify.error(err && (err as Error).message);
      } finally {
        loader.hide();
      }
    }
  }

  onUploadFile(file: ActivityEventAttachmentModel): void {
    window.open(this.uploadService.getDownloadUrl(file.path), '_blank');
  }

  onDeleteUploadFiles(files: IUploadedFile[]): void {
    files.forEach((file: IUploadedFile) => {
      this.uploadService.delete(file.path);
    });
  }

  get isEditing(): boolean {
    return !!(this.id || this.cloneId);
  }

  get filteredAttendantOptions(): UserModel[] {
    return this.isEditing ? this.attendantOptions : this.attendantOptions.filter((a) => a.isAtivo);
  }

  get isProspectType(): boolean {
    return this.clientType === ClientTypeEnum.Prospect;
  }

  get disableDateFields(): boolean {
    return !!this.id;
  }

  get maxTimeStartAt(): string | null {
    if (toIsoDateString(this.model.dataHoraInicio) === toIsoDateString(this.model.dataHoraFim)) {
      return this.endAtTime;
    }

    return null;
  }

  get minTimeEndAt(): string | null {
    if (toIsoDateString(this.model.dataHoraInicio) === toIsoDateString(this.model.dataHoraFim)) {
      return this.startAtTime;
    }

    return null;
  }

  get showConsolidatedSale(): boolean {
    return !!this.model.tipoHistorico?.flagPassivoVenda;
  }

  get disableEventClosed(): boolean {
    return this.model.situacao === ActivityEventStatusEnum.Closed;
  }

  get isConvertedProspect(): boolean {
    return this.client?.situacao === ClientStatusEnum.Converted;
  }

  get sendEventEmailDialogTitle(): string {
    const event = this.dialogConfig.sendEventEmail.event as ICalendarEvent;
    const title = event && event.name;
    return `${this.$t('crm.activityCalendar.dialog.activityCalendarEventView.title', { title })}`;
  }

  static extractTime(timeString: string): number[] {
    if (!timeString) {
      return [0, 0];
    }
    return timeString.split(':').map((x) => parseInt(x, 10));
  }

  static getHash(): string {
    return `${dayjs().format('HHmmssDD')}${padStart(random(1, 999).toString(), 3, '0')}`;
  }

  private validateDate(): void {
    if (dayjs(this.model.dataHoraFim).diff(this.model.dataHoraInicio, 'minute') < 0) {
      const startAt = this.model.dataHoraInicio;
      const { startAtTime } = this;

      setTimeout(() => {
        this.endAtTime = startAtTime;
        this.model.dataHoraFim = startAt;
      });
    }
  }

  private async loadModel(): Promise<void> {
    if (!this.id && !this.cloneId) {
      return;
    }

    const result = await this.activityService.getEvent(this.id || this.cloneId);

    let pickList: IKeyValue<string[]> | null = null;

    if (this.cloneId) {
      pickList = {
        pick: [
          'dataHoraInicio',
          'dataHoraFim',
          'timed',
          'anexos',
          'atendente',
          'cnpj',
          'nome',
          'origem',
          'descricao',
          'representante',
          'tipoHistorico',
          'titulo',
          'prospect',
          'tipo',
        ],
      };
    }

    const mappedObject = ObjectHelper.mapObjectValues<ActivityEventModel>(result, pickList);

    this.model = plainToClass(ActivityEventModel, mappedObject);
  }

  private async loadOptions(): Promise<void> {
    await Promise.all([this.loadRepresentatives(), this.loadOrigins(), this.loadGroups(), this.loadAttendants()]);
  }

  private async loadRepresentatives(): Promise<void> {
    const clientId = this.isProspectType ? this.client?.codCliente : this.client?.cnpjCpf;
    this.representativeOptions = await this.representativeService.getRepresentatives(clientId);
  }

  private async loadOrigins(): Promise<void> {
    this.originOptions = sortBy(await this.activityService.getOrigins(), 'descricao');
  }

  private async loadHistoryTypes(groupId: number): Promise<void> {
    this.loadingHistoryTypeOptions = true;
    this.historyTypeOptions = sortBy(await this.activityService.getHistoryTypes(groupId), 'nome');
    this.loadingHistoryTypeOptions = false;
  }

  private async loadGroups(): Promise<void> {
    this.groupOptions = sortBy(await this.activityService.getGroups(), 'descricao');
  }

  private async loadAttendants(): Promise<void> {
    this.attendantOptions = await this.activityService.getAttendants(false, true);
  }

  private setBusyLoader(): LoaderComponent {
    return this.$loading.show({
      container: this.$refs.calendarEventFormContainer,
      canCancel: false,
    });
  }
}
